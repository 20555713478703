import React from 'react';
import _ from 'lodash';
// import moment from 'moment-strftime';
import {graphql} from 'gatsby';

import {Layout} from '../components/index';
import {classNames, withPrefix, htmlToReact} from '../utils';
import PortfolioPostCategories from '../components/PortfolioPostCategories';
import PortfolioPostTags from '../components/PortfolioPostTags';

// this minimal GraphQL query ensures that when 'gatsby develop' is running,
// any changes to content files are reflected in browser
export const query = graphql`
  query($url: String) {
    sitePage(path: {eq: $url}) {
      id
    }
  }
`;

export default class Portfolio extends React.Component {
    render() {
        let has_image = false;
        let image_pos = _.get(this.props, 'pageContext.frontmatter.image_position', null) || 'top';
        if (_.get(this.props, 'pageContext.frontmatter.image', null)) {
             has_image = true;
        }
        return (
            <Layout {...this.props}>
            <article className="cards-portfolio py-5 py-sm-6 py-md-7">
            	<div className={classNames('portfolio__hero', 'container', {'container--medium': (image_pos === 'top') || (has_image === false)})}>
            		<div className={classNames('mb-4', {'mb-md-5': image_pos !== 'top', 'mb-md-6': image_pos !== 'top', 'grid': image_pos !== 'top', 'items-start': has_image && (image_pos !== 'top')})}>
            			{has_image && (
            			<div className={classNames('portfolio__image', 'mb-3', {'cell-12': image_pos !== 'top', 'cell-lg-7': image_pos !== 'top', 'mb-lg-0': image_pos !== 'top'})}>
            				<img src={withPrefix(_.get(this.props, 'pageContext.frontmatter.image', null))} alt={_.get(this.props, 'pageContext.frontmatter.image_alt', null)} />
            			</div>
            			)}
            			<header className={classNames('portfolio__header', {'cell-12': image_pos !== 'top', 'cell-lg-5': image_pos !== 'top', 'order-lg-first': has_image && (image_pos === 'right')})}>
            				<div className="portfolio__meta mb-2">
            					{_.get(this.props, 'pageContext.frontmatter.categories', null) && (<React.Fragment>
            						<PortfolioPostCategories {...this.props} categories={_.get(this.props, 'pageContext.frontmatter.categories', null)} container_class={'portfolio__cat'} />
            						{/* <span className="portfolio__meta-sep"> &middot; </span> */}
            					</React.Fragment>)}
            					{/* <span className="portfolio__date"><time dateTime={moment(_.get(this.props, 'pageContext.frontmatter.date', null)).strftime('%Y-%m-%d %H:%M')}>{moment(_.get(this.props, 'pageContext.frontmatter.date', null)).strftime('%B %d, %Y')}</time></span> */}
            				</div>
            				<h1 className="portfolio__title mt-0">{_.get(this.props, 'pageContext.frontmatter.title', null)}</h1>
            				{_.get(this.props, 'pageContext.frontmatter.subtitle', null) && (
            					<p className="portfolio__subtitle">{_.get(this.props, 'pageContext.frontmatter.subtitle', null)}</p>
            				)}
							{htmlToReact(_.get(this.props, 'pageContext.frontmatter.contente', null))}
            			</header>
            		</div>
            	</div>
            	<div className="container container--large">
            		<div className="portfolio__body text-block">
            			{htmlToReact(_.get(this.props, 'pageContext.html', null))}
            		</div>
            		{_.get(this.props, 'pageContext.frontmatter.tags', null) && (
            		<footer className="portfolio__footer mt-4 mt-md-5">
            			<PortfolioPostTags {...this.props} tags={_.get(this.props, 'pageContext.frontmatter.tags', null)} />
            		</footer>
            		)}
            	</div>
            </article>
            </Layout>
        );
    }
}
